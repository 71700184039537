import '~/scripts/integrations/jquery-extended';

(function() {
  var HIDDEN_CLASS = "visibility-hidden", CLASS = "header-menu-open";
  var scrollPosition = 0;
  var bgColor = $body.css("background-color");
  window.R = window.R || {};
  window.R.ui = window.R.ui || {};
  R.ui.Header = Header;

  var searchTimer = 0, searchOpenTimer = 0;

  function Header() {
    if ($("#header").length) {
      this.sharedLoadItems();
      this.menuTimer = 0;
      this.recognitionFormID = "#recognition-new-wrapper";
    }

    // Need events for non-header. For instance, the customer nav - login code.
    this.addEvents();
  }

  function initAutoComplete() {
    if ($("#header-search-input").length === 0) {
      return;
    }

    window.autocomplete = new window.Autocomplete({
      input: "#header-search-input",
      appendTo: "#header-search-results",
      success:function(data) {
        var str = {};
        str.email = data.email || "";

        if(data.web_url) { // presence of web_url means we have data from yammer
          str.yammer_id = data.id || "";
        }

        str.type = data.type || "";
        str.name = data.name || "";

        var url = "/goto";
        if(location.search.match(/^\?/)) {
          url = url + location.search + "&"+ $.param(str);
        } else {
          url = url + "?" + $.param(str);
        }
        Turbo.visit(url);
      }
    });
  }

  // For logging in from top customer nav
  Header.prototype.loadLoginHeader = function() {
    if (!this.idp)
      this.idp = new window.R.IdpRedirecter();

    this.idp.addEventsForAuthFields();
  };

  Header.prototype.sharedLoadItems = function() {
    if (window.Autocomplete) {
      initAutoComplete();
    }
    this.$menu = $(".header-menu");

    if (Modernizr.ios) {
      $(".ios header.ios-hidden").removeClass("ios-hidden");
    }
  };

  Header.prototype.addEvents = function() {
    var that = this;
    var $header = $("#header");

    if ($("#login-menu").length) {
      $("#login-menu").on("shown.bs.dropdown", function (event) {
        // Select login field when login-menu dropdown occurs.
        $(this).find('.user-session-email').select();
      });
    }

    if (!$header.length) {
      this.loadLoginHeader();
      return;
    }

    this.sharedLoadItems();

    $document.on("mouseover", "#header-users, #search-popup, #search-popup .ui-autocomplete", function (e) {
      e.preventDefault();
      clearTimeout(searchTimer);
      searchOpenTimer = setTimeout(function () {
        $("#search-popup").removeClass("visibility-hidden");
      }, 500);

    });

    $document.on("mouseout", "#header-users, #search-popup", function (e) {
      e.preventDefault();
      clearTimeout(searchOpenTimer);
      searchTimer = setTimeout(function () {
        $("#search-popup").addClass("visibility-hidden");
      }, 500);

    });

    $window.keyup(function (e) {
      if (e.keyCode === 27 && $body.hasClass(CLASS)) {
        that.closeMenu();
      }
    }.bind(this));

    $body.on(R.touchEvent, "#header-settings", function (e) {
      e.preventDefault();

      if ($body.hasClass(CLASS)) {
        that.closeMenu();
      } else {
        clearTimeout(that.menuTimer);
        $body.addClass(CLASS);
        that.$menu.removeClass(HIDDEN_CLASS);
        window.R.ui.drawer.close();
      }
    });

    $body.ready(function() {
      var anchorCount = $('#header-controls > a').length;
      var headerNavBarCount = $('#header-navbar > a').length;
      if (anchorCount + headerNavBarCount > 8) {
        $('#header-navbar, #dropdown-menu-collapsible-section').find('#header-fame').addClass('three-away');
        $('#header-rewards, #header-surveys').addClass('two-away');
      }
      else {
        $('#dropdown-custom').addClass('d-none');
      }
    });
  };

  Header.prototype.removeEvents = function() {
    $body.off(R.touchEvent,"#header-settings");
    $(".customer-marketing-nav .dropdown").off("shown.bs.dropdown");
    $window.unbind("keyup");
    $document
      .off("mouseout")
      .off("mouseover")
      .off(R.touchEvent, ".recognize-new");
    if(this.idp) {
      this.idp.removeEvents();
    }
  };

  Header.prototype.closeMenu = function() {
    var that = this;
    $body.removeClass(CLASS);
    this.menuTimer = setTimeout(function() {
      that.$menu.addClass(HIDDEN_CLASS);
    }, 500);
  };
})();
